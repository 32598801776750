$default: #ffffff;
$body-bg: #F0F3F4;

$default-text: #666666;
$content-text: $default-text;
$help-text: #949494;

$label-text: #ffffff;
$disabled: #dddddd;
$disabled-bg: tint($disabled, 15%);
$border: #d6d6d6;
$border-light: tint($border, 15%);
$input-border: shade($border, 5%);
$input-background: #ffffff;
$dropdown-text: #7d7d7d;

$sidebar: #046792;
$sidebar-text: #ffffff;

$mail-box: whitesmoke;
$auth-panel-background: #ffffff;
$progress-background: rgba(#000000, 0.07);
$progress-default: rgba(#000000, 0.15);

$bootstrap-panel-radius: 5px;
$bootstrap-panel-text: #7d7d7d;
$bootstrap-panel-bg: #ffffff;
$bootstrap-panel-header-bg: $bootstrap-panel-bg;
$bootstrap-panel-header-border: 1px solid rgba(0, 0, 0, 0.12);
$bootstrap-panel-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);

$primary: #BCD8F5 !default;
$info: #2dacd1 !default;
$success: #90b900 !default;
$warning: #dfb81c !default;
$danger: #e85656 !default;

$primary-light: tint($primary, 30%);
$info-light: tint($info, 30%);
$success-light: tint($success, 30%);
$warning-light: tint($warning, 30%);
$danger-light: tint($danger, 30%);

$primary-dark: shade($primary, 15%);
$info-dark: shade($info, 15%);
$success-dark: shade($success, 15%);
$warning-dark: shade($warning, 15%);
$danger-dark: shade($danger, 15%);

$primary-bg: tint($primary, 20%);
$info-bg: tint($info, 20%);
$success-bg: tint($success, 20%);
$warning-bg: tint($warning, 20%);
$danger-bg: tint($danger, 20%);

$activelink: $primary;
$hoverlink: $primary-dark;

@mixin body-bg() {
  background-color: $body-bg;
}
@mixin additional-bg() {
  //display: block; // additional background layer, hidden by default
}
