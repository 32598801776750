/* You can add global styles to this file, and also import other style files */
@import "@ng-select/ng-select/themes/default.theme.css";
//@import "node_modules/primeng/resources/themes/bootstrap/theme.css";

// @import "ag-grid/dist/styles/ag-theme-dark.css";
//@import "ag-grid/dist/styles/ag-theme-fresh.css";

// @import "ag-grid/dist/styles/ag-theme-material.css";

//@import "ag-grid-community/dist/styles/ag-grid.css";
//@import "ag-grid-community/dist/styles/ag-theme-blue.css";

@import '../node_modules/ag-grid-enterprise/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.min.css';

@import "primeng/resources/primeng.min.css";

//@import '@swimlane/ngx-datatable/index.css';
//@import '@swimlane/ngx-datatable/themes/material.scss';
//@import '@swimlane/ngx-datatable/assets/icons.css';

.ui-datepicker {
    max-width: 300px !important;
}

.ui-datepicker.ui-widget {
    max-width: 300px !important;
    padding: 0;
}

.btn-red {
    color: white;
    font-weight: bold;
    border-radius: 0px;
    border-color: #e94964;
    background-color: #e94964;
    &:hover {
        border-color: #e94964;
        background-color: #e94964;
    }
}

.btn-green {
    color: white !important;
    font-weight: bold;
    border-radius: 0px !important;
    border-color: #00a4a6 !important;
    background-color: #00a4a6 !important;
    &:hover {
        color: black !important;
    }
}

.btn-blue {
    color: white;
    font-weight: bold;
    border-radius: 0px;
    border-color: #00adef;
    background-color: #00adef;
    &:hover {
        border-color: #00adef;
        background-color: #00adef;
    }
}

.btn-brown {
    color: white;
    font-weight: bold;
    border-radius: 0px;
    border-color: #bc8f8f;
    background-color: #bc8f8f;
    &:hover {
        border-color: #bc8f8f;
        background-color: #bc8f8f;
    }
}

.btn-info {
    color: white;
}

.light-blue-background {
    background-color: #edf6ff;
}

.light-gray-background {
    background-color: #f3f3f3;
}

.light-green-background {
    background-color: #00a4a6;
}

.text-blue {
    color: #00adef;
}

.text-green {
    color: #00a4a6;
}

.text-gray {
    color: gray;
}

.text-red {
    color: #ff555e;
}

.text-orange {
    color: #ff8650;
}

.text-black {
    color: black;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-1 {
    opacity: 0.1;
}

.text-us-blue {
    color: #1c57a0;
}

.cursor-pointer {
    cursor: pointer;
}

.text-underline { text-decoration: underline; }

.p-accordion .p-accordion-content { border: none; }

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0.75rem;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-odd { padding: 0.75rem; }

datatable-header { padding-left: 10px; }

#idSelectButton div.p-button { padding: 5px 10px; font-size: 14px; }

div.p-datepicker { width: 350px; }

.p-datepicker table td { padding: 0; }

.inline-accordion {
    div.p-accordion-header { position: absolute; top: 0; }
    a { background: none !important; border: none !important; }
    div.p-accordion-tab { margin-bottom: 0 !important; }
    .p-accordion-header-link { color: black !important; }
    .p-accordion-header-link:focus { box-shadow: none !important; }
    .mt-card { border: 1px solid #cbcbcb !important; }
}

.white-space-initial { white-space: initial; }

.line-height-1 { line-height: 1; }

.modal-full {max-width: calc(100vw - 50px);}

.simple-notification-wrapper { z-index: 2000 !important; }

ng-dropdown-panel { box-shadow: 0 1rem 3rem #0000002d !important; z-index: 1120 !important; }

ng-select.no-border .ng-select-container { border: none; box-shadow: none !important; background: transparent; }

ng-select.no-border .ng-select-container .ng-value-container { padding-left: 5px; }

ng-dropdown-panel.no-border { border: none; }

.deleteBtnIcon { background-color: red !important; }

.font-monospaced { font-family: "JetBrains Mono", Menlo, Monaco, Consolas, "Courier New", monospace; }

// form-group, form-row, and form-inline classes have been removed in Bootstrap 5 for prevent issue below css added
.form-group {
    margin-bottom: 1rem;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row > .col {
    padding-left: 5px;
    padding-right: 5px;
}

label {
    margin-bottom: 0.5rem;
}

.card-footer, .card-header {
    color: #666;
    border-bottom: 1px solid #0000001f;
    height: 44px;
    font-size: 15px;
    padding: 14.5px 22px;
    background-color: #fff;
}

.mt-form-control-label-required {
    color: #ed7878;
}

.table-bordered > :not(caption) > * > * {
    padding: 5px 0px;
}

.font-weight-bold {
    font-weight: 700;
}

plusicon.p-icon-wrapper {
    padding-right: 14px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: 1px solid #c8c8c8;
    color: #333;
    background: #f4f4f4;
    font-weight: 700;
}

.p-inputswitch-small {
    .p-inputswitch .p-inputswitch-slider:before {
        width: 0.8rem;
        height: 0.8rem;
        margin-top: -.425rem;
        left: 0;
    }

    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        left: -0.3rem;
    }

    .p-inputswitch {
        width: 2rem;
        height: 1rem;
    }

    p-inputSwitch { vertical-align: middle; }
}